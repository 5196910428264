
@font-face {
    font-family: 'Trueno';
	font-display: swap;
    src: local('Trueno'),
		url('#{$font-path}/trueno/truenolt-webfont.woff2') format('woff2'),
		url('#{$font-path}/trueno/truenolt-webfont.woff') format('woff');
    font-weight: 200;
    font-style: normal;
}

@font-face {
    font-family: 'Trueno';
	font-display: swap;
    src: local('Trueno'),
		url('#{$font-path}/trueno/truenorg-webfont.woff2') format('woff2'),
		url('#{$font-path}/trueno/truenorg-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Trueno';
	font-display: swap;
    src: local('Trueno'),
		url('#{$font-path}/trueno/truenosbd-webfont.woff2') format('woff2'),
		url('#{$font-path}/trueno/truenosbd-webfont.woff') format('woff');
    font-weight: bold;
    font-style: normal;
}
