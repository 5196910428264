@import './variables.scss';

@import 'bootstrap/scss/bootstrap';

@import './fonts/fonts.scss';
@import './partials/header.scss';
@import './partials/footer.scss';
@import './partials/form.scss';
@import './partials/title-banner.scss';
@import './partials/nav.scss';
@import './partials/mentions.scss';
@import './partials/faq.scss';

@import '~react-jsonschema-form-validation/dist/react-jsonschema-form-validation.min.css';


/*//////////////
// GENERAL
/////////////*/

body, html {
  margin: 0;
  width: 100%;
  height: 100%;
  padding: 0;
  font-family: "Trueno", sans-serif;
  font-weight: 200;
  cursor: default;
  color: $grey-darker;
}

.red-circle {
	width: 2.5em;
	height: 2.5em;
	display: inline-flex;
	align-items: center;
	justify-content: center;
	border: 1.5px solid $red-cecaz;
	border-radius: 50%;
}

a {
	font-weight: normal;
}

.color-red {
	color: $red-cecaz;
}

.form-control:focus {
	border-color: #c1c1c1;
	box-shadow: 0 0 0 3px #c1c1c140;;
}


@keyframes spin {
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(360deg);
	}
}

.fa-spin {
	-webkit-animation: spin 2s infinite linear;
			animation: spin 2s infinite linear;
  }


.frame {
	max-width: 600px;;
	.frame-container {
	  overflow:hidden;
	  padding-bottom:56.25%;
	  position:relative;
	  height:0;
  }
  .frame-container iframe {
	  left:0;
	  top:0;
	  height:100%;
	  width:100%;
	  position:absolute;
  }
}

