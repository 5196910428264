
.faq {
	margin-top: 4rem;
	position: relative
}
.faq:before {
	content: '';
	position: absolute;
	left: 0;
	right: 0;
	top: -1.5rem;
	height: 1px;
	width: 10%;
	background-color: $grey-light;
	margin: auto;
	margin-bottom: 30px;
	display: block;
}
.faq h2 {
	margin-bottom: 1rem;
}
.faq h3 {
	font-size: 1.5rem;
	margin-top: 1rem;
	margin-bottom: 1rem;
}
.faq p {
	text-align: justify;
}

$imgShadow: 1px 1px 5px 2px #8a8a8a80;
.faq img {
	box-shadow: $imgShadow;
	max-width: 100%;
}

.faq .imgContainer {
	@media (min-width: $screen-xl) {
		box-shadow: $imgShadow;
		img {
			box-shadow: none;
		}
	}
}

.faq .youtube {
	max-width: 100%;
}

.FormFaqCode {
	img {
		width: auto;
		height: 600px;
	}
}


.FormFaqCode .steps  li,
.FormFaqCheckbook .steps  li,
.FormFaqCoord .steps li {
	margin-right: 50px;
	margin-bottom: 50px;
}
