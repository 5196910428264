

/*//////////////
// NAV BAR
/////////////*/

.navbar {
	height: 80px;
}

@include media-breakpoint-down(md) {
	.navbar {
		height: 60px;
	}
}
