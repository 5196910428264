@import '../../../styles/variables.scss';

.Mentions {
	font-size : 0.7em;
	text-align: justify;
	color: $grey;

   .mentions-title {
	   font-size: 1.2em;
   }
   a {
	color: inherit;
	&:hover {
		color: inherit;
	}
   }
}
